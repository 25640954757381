<template>


  <div >
    <div class="loading" v-if="!isLoad" >
      
      <video  class="video" 
      autoplay  
      loop
      muted
      src="https://res.cloudinary.com/dqvqk71l3/video/upload/c_scale,q_95,w_1100/v1628878651/yipi/Yipi_logo3Seg_yrslg7.mp4" />

    </div>
    <NavDrawer/>
    <FloatinLogo/>
    <Huella/>

    <QuienesSomos/>
 

    <Compromiso/>
    <Ofrecemos/>
    <Inversionista/>
    <Galeria/>
    <Viaja/>

    <Aliados/>
    <Emisiones/>
    <Entrega/>
    <Conductor/>
    <PuntosYipi/>
    <AdquiereYipi/>
    <Footer/>

    </div>
 
 
</template>

<script>

  import Huella from './Huella.vue'


 

  export default {
    name: 'Home',
    data: ()=> ({
      isLoad:false
    }),
    components: {
      Huella,
      FloatinLogo:()=>import('../components/FloatinLogo.vue'),
      NavDrawer:()=>import('../components/NavigationDrawer.vue'),
      QuienesSomos: () => import('./QuienesSomos.vue'),
      Compromiso: () => import('./Compromiso.vue'),
      Ofrecemos: () => import('./Ofrecemos.vue'),
      Galeria: () => import('./Galeria.vue'),
      Inversionista: () => import('./Inversionista.vue'),
      Viaja: () => import('./Viaja.vue'),
      Aliados:()=> import('./Aliados.vue'),
      Emisiones:()=> import('./Emisiones.vue'),
      Footer: ()=> import ('../components/Footer'),
      Entrega:()=> import('./Entrega.vue'),
      Conductor:()=> import ('./Conductor.vue'),
      PuntosYipi:()=>import ('./PuntosYipi.vue'),
      AdquiereYipi:()=>import('./AdquiereYipi.vue'),
    },
    mounted() {
    setTimeout(() => {
      this.isLoad=true
    }, 3000);
  }
  }
</script>
<style scoped>
  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 100001;
    background:white;

  }
  .video{

  width: 100%;
  height: 100%;
}
  
</style>